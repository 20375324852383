// Override default variables before the import
$body-bg: #f5fdff;
$text-color: #4373a6;
$layout-breakpoint-small: 720px;




body,
#root {
    min-height: 100%;
    display: flex
}

@import "../node_modules/bootstrap/scss/functions";
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

#loading {

    img {
        width: 200px;
        max-width: 300px;
    }
}

#loading,
main {
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.beta-badge{
    position:absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    text-decoration: none;
}

main {
    display: flex;
    background: linear-gradient(#fff, #fff, #fff, #bef1fd);

    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 0 20px;

    section {
        flex-grow: 1;
        width: 100%;
        margin: auto;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: #4373a6 transparent;
        padding: 0 20px;

        &.question,
        &.app-layout {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-height: 100vh;
            height: 100vh !important;

            h1 {
                text-align: center;
            }

            .content {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;



                .quest-page {
                    width: 100%;
                    max-height: 100vh;

                    .topic {
                        text-align: center;
                        margin: 20px 0;

                        .card,
                        .alert {
                            display: inline-block;
                            padding: 10px 30px;
                            color: white;

                            svg {
                                color: white
                            }

                            .progress {
                                margin-top: 10px;
                            }

                            // .wrapper{
                            //     display: flex;
                            //     // .progress{
                            //     //     display:block;
                            //     //     width:10px;
                            //     //     background-color:red;
                            //     //     margin: 10px;
                            //     // }
                            // }
                        }

                        &.pending {
                            .alert {
                                opacity: 0.5;
                            }
                        }
                            small {
                                display: block;
                                font-size: 0.7em;
                                color: #999;
                                margin: 10px 0;
                            }

                            &.open small {
                                color: #333;
                            }

                            .badge {
                                position: absolute;
                                margin-left: 35px;
                                margin-top: -15px;
                            }
                        }
                    }

                    .dashboard-body {
                        flex-grow: 1;
                        margin: 0;

                        .card.quest {
                            text-decoration: none;
                        }

                        .stats {
                            .alert {
                                text-align: center;

                                small {
                                    display: block;
                                    overflow: hidden;
                                    height: 1.5em;
                                    line-height: 1.5em;
                                    ;
                                    // word-wrap: break-word;
                                }
                            }
                        }
                    }

                    img {
                        max-width: 70%
                    }

                    .answer {
                        margin: 5px 0;
                        // color: white !important;
                        text-align: left;
                        width: 100%;
                        max-width: 400px;
                    }

                    &>label,
                    &>small {
                        display: block !important;
                        width: 100%;
                        text-align: left;

                    }

                    button {
                        width: 100%;
                        margin: 20px 0;
                    }

                    .error-page {
                        img {
                            max-width: 100%;

                        }
                    }
                }

                header,
                footer {
                    padding: 20px;
                    text-align: center;

                    img {
                        max-height: 20vh;

                        &.logo {
                            max-height: 8vh;
                            margin: 2vh 0;
                        }
                    }

                    button {
                        width: 100%
                    }
                }
            }
        }
    }




    @media (min-width: $layout-breakpoint-small) {
        main {
            section {
                width: $layout-breakpoint-small;

            }
        }
    }